export const appConfig = {
    apiUrl: 'https://jobs.dz-privatbank.com/services/jobs/options/facetValues/',
    baseTargetUrl: 'https://jobs.dz-privatbank.com/search/',
    defaultQueryParams: {
        createNewAlert: 'false',
        q: '',
        locationsearch: '',
    },
    defaultFieldsItem: {key: 'Alle', value: ''},
    defaultFields: {
        location: [
            {key: 'Alle', value: ''},
            {key: 'Düsseldorf, DE', value: 'Düsseldorf, DE'},
            {key: 'Frankfurt, DE', value: 'Frankfurt, DE'},
            {key: 'Nürnberg, DE', value: 'Nürnberg, DE'},
            {key: 'Strassen, LU', value: 'Strassen, LU'},
            {key: 'Stuttgart, DE', value: 'Stuttgart, DE'},
            {key: 'Zürich, CH', value: 'Zürich, CH'},
        ],
        customfield1: [
            {key: "Alle", value: ""},
            {key: "Datenschutz", value: "Datenschutz"},
            {key: "Fondsdienstleistungen", value: "Fondsdienstleistungen"},
            {key: "Gremien/Beteiligung", value: "Gremien/Beteiligung"},
            {key: "IT", value: "IT"},
            {key: "Konzernstrategie, Gremien & Marketing", value: "Konzernstrategie, Gremien & Marketing"},
            {key: "Kredit", value: "Kredit"},
            {key: "Operations/Services", value: "Operations/Services"},
            {key: "Organisation/Verwaltung", value: "Organisation/Verwaltung"},
            {key: "Personal", value: "Personal"},
            {key: "Portfoliomanagement", value: "Portfoliomanagement"},
            {key: "Private Banking", value: "Private Banking"},
            {key: "Private Wealth Management", value: "Private Wealth Management"},
            {key: "Treasury/Brokerage", value: "Treasury/Brokerage"},
            {key: "Unternehmenssteuerung", value: "Unternehmenssteuerung"},
            {key: "Verwahrstelle", value: "Verwahrstelle"},

        ],
        customfield2:[
            {key: "Alle", value: ""},
            {key: "Berufseinsteiger", value: "Berufseinsteiger"},
            {key: "Berufserfahrene", value: "Berufserfahrene"},
            {key: "Praktikum", value: "Praktikum"},
            {key: "Traineeprogramm", value: "Traineeprogramm"},
            {key: "Werkstudententätigkeit", value: "Werkstudententätigkeit"},
        ]
    },
    steps: {
        step1: {
            title: 'Gerne helfe ich Ihnen, die richtige Stelle bei uns zu finden.',
            subTitle: 'Willkommen auf unserer Karriereseite',
            nextButtonText: "Los geht's!",
        },
        step2: {
            title: 'In welcher Stadt könnten Sie sich vorstellen zu arbeiten?',
            nextButtonText: 'Weiter',
            field: 'location',
        },
        step3: {
            title: 'In welchem Fachgebiet möchten Sie gerne arbeiten?',
            nextButtonText: 'Weiter',
            field: 'customfield1',
        },
        step4: {
            title: 'Welches Erfahrungslevel bringen Sie mit?',
            nextButtonText: 'Stellen anzeigen',
            field: 'customfield2',
        },
    },
    defaultContent: {
        backLinkText: 'Zurück',
        headerImageTitle: 'Christina Krauß',
    }
};
