import React from 'react';
import './App.css';
import Form from "./components/Form/Form";


function App() {
  return (
    <Form/>
  );
}

export default App;
