import React, {useLayoutEffect} from 'react';
import RadioButton from '../RadioButton/RadioButton';
import avatar from '../../images/DZPB_Foto_Christina_Krauß_276.jpg';


const StepLayout = ({
                        stepConfig,
                        defaultConfig,
                        stepCount,
                        currentStep,
                        handleSelect,
                        handleSubmit,
                        selectedFields,
                        fieldsData,
                    }) => {

    useLayoutEffect(() => {
        window.addEventListener('resize', setStepContentHeight);
        setStepContentHeight()
        return () => window.removeEventListener('resize', setStepContentHeight);
    });

    const setStepContentHeight = () => {
        const elem = document.querySelector('.step-content');
        if (elem) {
            document.documentElement.style.setProperty('--step-content-height', elem ? `${elem.offsetHeight}px` : '0');
        }
    };

    const isSelected = (field, value) => {
        return Boolean(selectedFields[field] === value)
    };

    return (
        <div className="step-container">
            {
                currentStep === 1 && <div className="step-header">

                    <div className="avatar-container">
                        <div className="avatar">
                            <img src={avatar} alt="avatar"/>
                        </div>
                        <h5 className="avatar-title">{defaultConfig.headerImageTitle}</h5>
                    </div>
                </div>
            }

            <div className={`title-container${currentStep === 1 ? ' first-step-title-container' : ''}`}>
                {stepConfig.subTitle && <h4 className="sub-title">{stepConfig.subTitle}</h4>}
                <h2 className={`title${currentStep === 1 ? ' reduce-max-width first-step-title' : ''}`}>{stepConfig.title}</h2>
            </div>
            {
                stepConfig.field  && <div className="step-content">
                    <div className="radio-btn-container">

                        {fieldsData[stepConfig.field].map((i, index) => (
                            <RadioButton key={index}
                                         text={i.key}
                                         value={i.value}
                                         isSelected={isSelected(stepConfig.field, i.value)}
                                         handleClick={handleSelect}/>
                        ))}

                    </div>
                </div>
            }

        </div>
    )
}

export default StepLayout;
