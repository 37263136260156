import React from 'react';
import {appConfig} from '../../constants/config';
import Button from '../Button/Button';
import StepDots from '../StepDots/StepDots';
import BackLink from '../BackLink/BackLink';

const NavBar = ({
                    stepCount,
                    currentStep,
                    handleStep,
                    handleSubmit,
                }) => (
    <div className="step-nav-container">
        <div className="step-btn-container">
            <BackLink text={appConfig.defaultContent.backLinkText} showBackLink={currentStep > 1}
                      handleClick={() => handleStep('prev', currentStep)}/>
            <Button text={appConfig.steps[`step${currentStep}`].nextButtonText}
                    handleClick={() => Object.keys(appConfig.steps).length === currentStep ? handleSubmit() : handleStep('next', currentStep)}/>
        </div>
        <StepDots count={stepCount} currentIndex={currentStep}/>
    </div>
);

export default NavBar;
