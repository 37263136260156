import React, { useState, useEffect } from 'react';
import StepLayout from '../StepLayout/StepLayout';
import NavBar from '../NavBar/NavBar';
import { appConfig } from '../../constants/config';
import { generateTargetLink } from '../../utils/helpers';
import { getFieldsData } from '../../utils/apiClient';


const Form = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const initialSelectedFields = {
        location: '',
        customfield1: '',
        customfield2: ''
    };

    const [selectedFields, setFieldValue] = useState(initialSelectedFields);
    const [isMounted, setIsMounted] = useState(false);

    const fieldsDataInitialState = {
        location: [],
        customfield1: [],
        customfield2: []
    };
    const [fieldsData, setFieldsDataInitialState] = useState(fieldsDataInitialState);


    useEffect( () => {
        if (!isMounted) {
            setIsMounted(true);
            return;
        }

        const fetchData =  async () => {
            const res = await getFieldsData();
            let data = appConfig.defaultFields;
            if (res){
                data = {
                    location : [appConfig.defaultFieldsItem, ...res.location.map(i => ({key: i.name, value:i.name}))],
                    customfield1 : [appConfig.defaultFieldsItem, ...res.customfield1.map(i => ({key: i.name, value:i.name}))],
                    customfield2 : [appConfig.defaultFieldsItem, ...res.customfield2.map(i => ({key: i.name, value:i.name}))]
                };
            }
            setFieldsDataInitialState(data)
        };
        fetchData();
    }, [isMounted]);



    const handleStep = (action, currentStep) => {
        let step = 1;
        switch (action) {

            case 'next':
                step = currentStep < 4 ? currentStep + 1 : currentStep;
                break;
            case  'prev':
                step = currentStep > 1 ? currentStep - 1 : currentStep;
                break;
            default:
                break
        }
        return setCurrentStep(step);
    };

    const handleSelect = (value) => {
        setFieldValue({...selectedFields, [appConfig.steps[`step${currentStep}`].field]: value})
    };

    const handleSubmit = () => {
        const targetLink = generateTargetLink(appConfig.baseTargetUrl, appConfig.defaultQueryParams, selectedFields);

        const eventData = {
            eventName: 'widgetRedirectEvent',
            targetLink
        };

        if (window.parent){
            window.parent.postMessage(eventData,'*' );
        }
    };

    return (
        <div className="form-container">
            <StepLayout
                stepCount={Object.keys(appConfig.steps).length}
                currentStep={currentStep}
                stepConfig={{...appConfig.steps[`step${currentStep}`]}}
                defaultConfig={appConfig.defaultContent}
                handleSelect={handleSelect}
                handleStepChange={handleStep}
                handleSubmit={handleSubmit}
                selectedFields={selectedFields}
                fieldsData={fieldsData}
            />
            <NavBar
                stepCount={Object.keys(appConfig.steps).length}
                currentStep={currentStep}
                handleStep={handleStep}
                handleSubmit={handleSubmit}
            />
        </div>
    );
};

export default Form;
