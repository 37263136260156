import React from 'react';
import arrowLeftIcon from '../../images/chevron-left.svg';


const BackLink = ({text, handleClick, showBackLink = true}) => (
    <div
        className={`back-link-btn${showBackLink? '': ' back-link-btn-hidden'}`}
        onClick={handleClick}
    >
        <div className="back-link-btn-icon"><img className="icon" src={arrowLeftIcon} alt="icon"/></div>
        {text}
    </div>
);

export default BackLink;
