import React from 'react';


const Button = ({text, handleClick}) => (
    <div
        className='btn'
        onClick={handleClick}
    >
        {text}
    </div>
);

export default Button;
