import React from "react";


const Dot = ({isCurrent, isVisited, isHidden}) => (
    <div className={`dot${isCurrent ? ' dot-current' : ''} ${isHidden? 'dot-hidden': ''}`}>
        <div className={`dot-inner${isVisited ? ' dot-inner-visited' : ' dot-inner-default'}`}/>
    </div>
);

const StepDots = ({count, currentIndex}) => {

    const dots = [];

    for (let i = 1; i <= count-1; i++) {
        dots.push(<Dot
            key={i}
            isVisited={Boolean(i<= currentIndex-1)}
            isCurrent={Boolean(i === currentIndex-1)}
            isHidden={Boolean(currentIndex === 1)}
        />)
    }

    return (
    <div className="step-dot-container">
        {dots}
    </div>
)};

export default StepDots
