import axios from 'axios';
import { appConfig } from '../constants/config';

const axiosClient = axios.create();

export const getFieldsData = async () => {

    const params = {
        page: 0,
        keywords: '',
        locationsearch: '',
        sortby: 'referencedate',
        sortdir: 'desc',
        sortfield: 'title',
        recordsperpage: 25,
        startrow: 0,
        facetquery: {
            facet: true,
            mincount: 1,
            limit: 5000,
            fields: [
                'location',
                'customfield1',
                'customfield2'
            ],
            sort: 'index',
            showPicklistAllLocales: false
        },
        filterquery: {}
    };
    try {

        const res = await axiosClient.post(appConfig.apiUrl, params);

        return res.data?.facets?.map || null;

    }catch {
        return null
    }
}
