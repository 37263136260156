export const makeQueryString = (query, prefix) => {
    let str = [], prop;
    for (prop in query) {
        if (query[prop] || query[prop] === '') {
            if (query.hasOwnProperty(prop)) {
                let key = prefix ? prefix + '[' + prop + ']' : prop,
                    value = query[prop];
                str.push((value !== null && typeof value === 'object') ?
                    makeQueryString(value, key) :
                    encodeURIComponent(key) + '=' + encodeURIComponent(value));
            }
        }
    }
    return str.join('&');
};

export const generateTargetLink = (baseUrl, defaultFields, selectedFields) => {

    const params = {
        ...defaultFields,
        optionsFacetsDD_location: selectedFields.location || '',
        optionsFacetsDD_customfield1: selectedFields.customfield1 || '',
        optionsFacetsDD_customfield2: selectedFields.customfield2 || '',

    };

   return `${baseUrl}?${makeQueryString(params)}`;

};
