import React from 'react';
import plusIcon from '../../images/plus.svg';
import checkIcon from '../../images/check.svg';


const RadioButtonIcon = ({isSelected}) => (
    <div className="radio-btn-icon"><img className="icon" src={isSelected? checkIcon: plusIcon} alt="icon"/></div>
);


const RadioButton = ({text, value, handleClick, isSelected}) => (
    <div
        className={`radio-btn${isSelected? ' selected': ''}`}
        onClick={() => handleClick(value)}
    >
        <RadioButtonIcon isSelected={isSelected}/>
        {text}
    </div>
);

export default RadioButton;
